import { ForwardedRef, type RefObject, forwardRef } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { Badge } from 'theme-ui';

import { Flex, Paragraph, Spacer } from 'voom-gatsby';

import { SelectDropdown } from './SelectDropdown';

type DropdownQuestionProps = {
  ref?: RefObject<HTMLDivElement>;
  text: string | JSX.Element;
  pillText: string;
  menuItems: string[];
  breakLines?: boolean;
  disabled?: boolean;
  register: { name: string };
  setValue: UseFormSetValue<FieldValues>;
  onSelectChange?: () => void;
};

export const DropdownQuestion = forwardRef(
  (props: DropdownQuestionProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      text,
      pillText,
      menuItems,
      disabled,
      register,
      setValue,
      breakLines,
      onSelectChange,
    } = props;

    return (
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          opacity: disabled ? 0.3 : 1,
          pointerEvents: disabled ? 'none' : 'auto',
        }}
      >
        <Badge
          sx={{
            backgroundColor: 'rgba(247, 247, 249, 1)',
            fontSize: 'xs',
            color: 'rgba(218, 27, 133, 1)',
            maxWidth: '86px',
            padding: '8px 22px 8px 22px',
            borderRadius: 'rounded',
          }}
        >
          {pillText}
        </Badge>
        <Spacer />
        <Flex
          sx={{
            flexFlow: breakLines ? 'column wrap' : 'row wrap',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paragraph
            variant="contentTitleLg"
            sx={{
              textAlign: 'center',
              fontSize: ['26px', null, '5xl', null, '6xl'],
            }}
            ref={ref}
          >
            {text}
          </Paragraph>
          <SelectDropdown
            menuItems={menuItems}
            register={register}
            setValue={setValue}
            onSelectChange={onSelectChange}
            fullWidth={breakLines}
          />
          <input type="hidden" {...register} />
        </Flex>
      </Flex>
    );
  },
);

DropdownQuestion.displayName = 'DropdownQuestion';
