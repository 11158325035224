import { useAtomValue } from 'jotai';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import ReactSlickSlider, { Settings } from 'react-slick';

import { Link, SliderNextArrow, SliderPrevArrow } from 'voom-gatsby';

import { DoctorResultCard } from '~components';

import {
  SelectedDoctorProps,
  mobileResultsViewAtom,
} from '~hooks/use-doctor-results';

import { getMapBounds } from '~utils/map';

import { DoctorResult } from '~types/sanity';

interface DoctorResultCarouselProps {
  doctors: DoctorResult[];
  selectedDoctor: SelectedDoctorProps | null;
  setSelectedDoctor: Dispatch<SetStateAction<SelectedDoctorProps | null>>;
  googleMapInstance: google.maps.Map | null;
}

export const DoctorResultsCarousel = ({
  doctors,
  selectedDoctor,
  setSelectedDoctor,
  googleMapInstance,
}: DoctorResultCarouselProps) => {
  const ref = useRef<ReactSlickSlider>(null);
  const mobileView = useAtomValue(mobileResultsViewAtom);

  const doctorResultCarousel: Settings = {
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    prevArrow: <SliderPrevArrow variant="slickArrowSm" sx={{ left: 2 }} />,
    nextArrow: <SliderNextArrow variant="slickArrowSm" sx={{ right: 2 }} />,
    beforeChange: (_, next) => {
      setSelectedDoctor({
        doctor: doctors[next].id,
        office: doctors[next]._key,
      });
      googleMapInstance &&
        getMapBounds(googleMapInstance, [doctors[next].geolocation]);

      // zoom in on map
      mobileView === 'map' && googleMapInstance?.setZoom(12);
    },
  };
  // TODO: rethihnk how to do this without index
  // useEffect(() => {
  //   selectedDoctor && ref.current?.slickGoTo(selectedDoctor);
  // }, [selectedDoctor]);

  return (
    <ReactSlickSlider
      // Typescript errors, but it works. needed to use slickGoTo
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      {...doctorResultCarousel}
      sx={{
        // only render on mobile
        display: ['block', null, null, 'none'],
        position: 'absolute',
        bottom: '24px',
        left: 0,
        right: 0,

        '.slick-list': {
          '.slick-slide': {
            mx: 4,
          },
        },
      }}
    >
      {doctors.map((doctor, index) => {
        return (
          <DoctorResultCard
            key={doctor.streetAddress + index}
            doctor={doctor}
            selectedDoctor={selectedDoctor}
            setSelectedDoctor={setSelectedDoctor}
            type="CAROUSEL"
            sx={{
              isolation: 'isolate',
              bg: 'white',
              height: 145,
            }}
          />
        );
      })}
    </ReactSlickSlider>
  );
};
