import { GoogleMap, MarkerClustererF, MarkerF } from '@react-google-maps/api';
import { Dispatch, SetStateAction } from 'react';

import { DoctorResultMarker } from '~components';

import { SelectedDoctorProps } from '~hooks/use-doctor-results';

import { scrollToDataId } from '~utils/helpers';
import { mapOptions } from '~utils/map';

import { DoctorResult } from '~types/sanity';

interface DoctorMapProps {
  isLoaded: boolean;
  selectedDoctor: SelectedDoctorProps | null;
  setSelectedDoctor: Dispatch<SetStateAction<SelectedDoctorProps | null>>;
  userLocation: { lat: number; lng: number } | null;
  doctorResults: DoctorResult[] | null;
  onLoad: (map: google.maps.Map) => void;
  onUnmount?: () => void;
  defaultZoom?: number;
}
export const DoctorMap = ({
  isLoaded,
  selectedDoctor,
  setSelectedDoctor,
  userLocation,
  doctorResults,
  onLoad,
  onUnmount,
  defaultZoom = 10,
}: DoctorMapProps) => {
  return (
    isLoaded &&
    userLocation && (
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          inset: 0,
        }}
        center={userLocation}
        zoom={defaultZoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={mapOptions}
      >
        <MarkerF
          position={userLocation}
          title="User location map marker"
          icon="/icon-geomarker-self.svg"
        />
        <MarkerClustererF
          options={{
            minimumClusterSize: 3,
            averageCenter: true,
            enableRetinaIcons: true,
            gridSize: 40,
            maxZoom: 15,
            title: 'Doctors in the area',
            styles: [
              {
                url: '/cluster/m1.png',
                height: 53,
                width: 53,
              },
            ],
          }}
        >
          {(clusterer) => (
            <>
              {doctorResults?.map((o, index) => {
                return (
                  <DoctorResultMarker
                    key={o.geolocation.lat + index}
                    activeDoctor={o.id === selectedDoctor?.doctor}
                    activeLocation={o._key === selectedDoctor?.office}
                    position={{
                      lat: o.geolocation.lat,
                      lng: o.geolocation.lng,
                    }}
                    doctor={o}
                    clusterer={clusterer}
                    onClick={() => {
                      setSelectedDoctor({ doctor: o.id, office: o._key });
                      scrollToDataId(o.id);
                    }}
                  />
                );
              })}
            </>
          )}
        </MarkerClustererF>
      </GoogleMap>
    )
  );
};
