import { useEffect, useState } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

export interface UserLocation {
  address?: string | null;
  lat: number;
  lng: number;
}

/**
 * Use user location
 *
 * first check if there is a query string for lat, lng, or address
 * if not, fetch the user's location based on their IP address
 * if the fetch fails, set the user's location to New York
 *
 * @returns {UserLocation} userLocation
 */
export const useUserLocation = () => {
  const [userLocation, setUserLocation] = useState<UserLocation | null>(null);

  const [query] = useQueryParams({
    address: StringParam,
    lat: NumberParam,
    lng: NumberParam,
  });

  useEffect(() => {
    const getIpgeo = async () => {
      try {
        const response = await fetch(`/geolocation`);
        const data = await response.json();
        setUserLocation({
          lat: data.geo.latitude,
          lng: data.geo.longitude,
        });
      } catch {
        // Set to New York if netlify edge function fails
        setUserLocation({
          lat: 40.7127753,
          lng: -74.0059728,
        });
      }
    };

    if (query && query.lat && query.lng) {
      setUserLocation({
        lat: query.lat,
        lng: query.lng,
        address: query.address,
      });
    } else {
      getIpgeo();
    }
  }, [query]);

  return userLocation;
};
