import { useBreakpointIndex } from '@theme-ui/match-media';
import { motion } from 'framer-motion';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { Box } from 'theme-ui';

import {
  Button,
  Flex,
  Grid,
  Heading,
  Link,
  Paragraph,
  Spacer,
  Text,
  ThemeUIStyleObject,
  useThemeUI,
} from 'voom-gatsby';

import { ConsultationTag } from '~components';

import { SelectedDoctorProps } from '~hooks/use-doctor-results';

import { ReactComponent as GeoMarkerIcon } from '~svg/icons/icon-geomarker.svg';

import { DoctorResult } from '~types/sanity';

export const DoctorResultCard = ({
  doctor,
  selectedDoctor,
  setSelectedDoctor,
  type = 'LIST',
  sx,
  className,
}: {
  doctor: DoctorResult;
  selectedDoctor: SelectedDoctorProps | null;
  setSelectedDoctor: Dispatch<SetStateAction<SelectedDoctorProps | null>>;
  type?: 'LIST' | 'CAROUSEL' | 'MARKER';
  sx?: ThemeUIStyleObject;
  className?: string;
}) => {
  const active = type === 'LIST' && doctor.id === selectedDoctor?.doctor;

  const { theme } = useThemeUI();

  return (
    <motion.div
      onMouseEnter={() =>
        setSelectedDoctor({ doctor: doctor.id, office: doctor._key })
      }
      data-id={doctor.id}
      variants={{
        default: {
          y: 0,
          // Theme error event though doctorResultsShadow exists
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          boxShadow: theme.shadows?.['doctorResultsShadow'],
        },
        active: {
          y: -5,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          boxShadow: theme.shadows?.['doctorResultsActiveShadow'],
        },
      }}
      animate={active ? 'active' : 'default'}
      sx={{
        height: [null, null, 300],
        my: 3,
        borderRadius: 'corner',
        overflow: 'hidden',
        textDecoration: 'none',
        ...sx,
      }}
      className={className}
    >
      <Link
        to={`/find-a-doctor/doctors/${doctor.slug.current}`}
        variant="ghost"
        sx={{ textDecoration: 'none' }}
      >
        <Grid
          sx={{
            gridTemplateColumns: [
              '110px 2fr',
              null,
              '215px 1fr',
              null,
              '240px 1fr',
            ],
            gridGap: 0,
            height: 'full',
          }}
        >
          <Box sx={{ p: [1, null, 0] }}>
            <GatsbyImage
              image={doctor.image.asset.gatsbyImageData}
              alt={doctor.image.alt}
              sx={{
                overflow: 'hidden',
                width: 'full',
                maxWidth: 'full',
                height: 'full',
                img: {
                  borderRadius: 'corner',
                  borderTopRightRadius: [null, null, 'square'],
                  borderBottomRightRadius: [null, null, 'square'],
                  objectPosition: 'top',
                },
              }}
            />
          </Box>
          <DoctorResultCardCopy doctor={doctor} type={type} />
        </Grid>
      </Link>
    </motion.div>
  );
};

export const DoctorResultCardCopy = ({
  doctor,
  type,
}: {
  doctor: DoctorResult;
  type: 'LIST' | 'CAROUSEL' | 'MARKER';
}) => {
  const breakpointIndex = useBreakpointIndex();

  const mobileView = breakpointIndex < 2 || type === 'MARKER';

  const hasAdditionalOffices = doctor.additionalOffices.length > 0;
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        pt: type === 'MARKER' ? 2 : [3, null, 5],
        pb: type === 'MARKER' ? 3 : [3, null, 5],
        px: type === 'MARKER' ? 3 : [2, null, 5],
        ...(type === 'LIST' ? { minHeight: 150 } : {}),
      }}
    >
      <Heading
        variant="heading"
        sx={{
          fontSize: type === 'MARKER' ? 'xs' : ['xs', null, '2xl'],
          display: 'flex',
          flexWrap: 'wrap',
          '@media (hover: hover)': {
            ':hover span': {
              textDecoration: 'underline',
            },
          },
        }}
      >
        <Text
          sx={{
            fontWeight: 'semibold',
            mr: 2,
          }}
        >
          {doctor.name}
        </Text>
        {doctor.credentials && <Text>{doctor.credentials}</Text>}
      </Heading>
      <Spacer space={2} />
      <Paragraph
        variant={mobileView ? 'copyright' : 'mediumP'}
        sx={{
          display: 'flex',
          mb: 0,
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        {doctor.distance && (
          <>
            <GeoMarkerIcon
              sx={{ mr: 2, width: 16, height: 16, color: 'black' }}
            />
            <Text sx={{ fontWeight: 'medium' }}>{`${doctor.distance.toFixed(
              doctor.distance < 20 ? 1 : 0,
            )} mi.`}</Text>
            <Text
              sx={{
                mx: mobileView ? '2px' : 1,
                borderRight: (theme) => `1px solid ${theme.colors?.grey300}`,
              }}
            />
          </>
        )}
        <Text>{`${doctor.city.name}, ${doctor.state.abbreviation}`}</Text>
      </Paragraph>

      {hasAdditionalOffices ? (
        <>
          <Spacer space={[null, null, 1]} />
          <Paragraph
            variant={mobileView ? 'copyright' : 'smallP'}
            sx={{
              mb: 0,
              ml: doctor.distance ? 4 : 0,
              whiteSpace: 'nowrap', // Force to stay on one line and hide overflow
              overflow: 'hidden',
            }}
          >
            {mobileView ? (
              <Text
                sx={{ color: 'grey500' }}
              >{`+${doctor.additionalOffices.length} More Location${doctor.additionalOffices.length > 1 ? 's' : ''}`}</Text>
            ) : (
              <>
                <Text sx={{ color: 'black' }}>Locations In: </Text>
                {doctor.additionalOffices.map((office, index) => (
                  <Fragment key={office._key}>
                    <Text sx={{ color: 'grey500' }}>
                      {`${office.city.name}, ${office.state.abbreviation}`}
                    </Text>
                    {index < doctor.additionalOffices.length - 1 && (
                      <Text
                        sx={{
                          mx: mobileView ? '2px' : 2,
                          color: 'grey500',
                        }}
                      >
                        |
                      </Text>
                    )}
                  </Fragment>
                ))}
              </>
            )}
          </Paragraph>
          <Spacer space={[2, null, 3]} />
        </>
      ) : (
        <Spacer space={[2, null, 3]} />
      )}
      <Flex
        sx={{
          flexDirection: 'row',
          gridArea: 'consultations',
          alignItems: 'flex-start',
          gap: 2,
          ml: doctor.distance && !mobileView ? 4 : 0,
        }}
      >
        {doctor.consultations.map((consultation) => (
          <ConsultationTag
            key={consultation}
            consultation={consultation}
            sx={{
              bg: 'tan',
            }}
          />
        ))}
      </Flex>
      <Spacer space={2} />
      {type === 'LIST' && (
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: [1, null, 3],
            mt: 'auto',
          }}
        >
          <Button
            variant={mobileView ? 'primarySm' : 'primary'}
            onClick={(e) => {
              // This must be a button because its inside a link, we override the link behavior
              // and send users to the doctor page and open the contact form
              e.preventDefault();
              navigate(doctor.contactLink);
            }}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {mobileView ? `Email` : `Email This Doctor`}
          </Button>
          {doctor.phoneNumber && (
            <Button
              variant={mobileView ? 'outlineSm' : 'outline'}
              sx={{ whiteSpace: 'nowrap' }}
              onClick={(e) => {
                // This must be a button because its inside a link, we override the link behavior
                // and send users to the doctor page and open the contact form
                e.preventDefault();
                navigate(`tel:${doctor.phoneNumber?.tel}`);
              }}
            >
              <Text>{mobileView ? 'Call' : doctor.phoneNumber?.display}</Text>
            </Button>
          )}
        </Flex>
      )}
      {type === 'MARKER' && (
        <Link variant="buttonSm" sx={{ whiteSpace: 'nowrap' }} to={doctor.link}>
          <Text>View Profile</Text>
        </Link>
      )}
    </Flex>
  );
};
