import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ForwardedRef, forwardRef } from 'react';

import {
  Button,
  Container,
  Flex,
  Grid,
  Paragraph,
  Section,
  Spacer,
} from 'voom-gatsby';

import { BunionplastyBackground, ContentBlock } from '~components';

import { ContentBlockProps as ContentBlockInterface } from '~types/sanity';

type BunionplastyQuizHeroProps = {
  heroImage: IGatsbyImageData | undefined;
  onQuizStart: () => void;
  data: {
    content: ContentBlockInterface;
    disclaimer: string;
  };
};

export const BunionplastyQuizHero = forwardRef(
  (props: BunionplastyQuizHeroProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { heroImage, onQuizStart, data } = props;
    const { disclaimer } = data;
    return (
      <Section
        sx={{
          bg: 'beige',
          pt: [0, 0, 0, 0],
          pb: [0, 0, 0, 0],
        }}
        ref={ref}
      >
        <BunionplastyBackground
          sx={{
            left: [0, null, -400],
            bottom: [0, null, 500],
            width: [0, null, 1400],
            zIndex: [0, null, -1],
          }}
        />
        <Grid
          columns={[1, null, null, 2]}
          sx={{
            gridAutoFlow: 'dense',
          }}
        >
          {heroImage && (
            <GatsbyImage
              image={heroImage}
              alt="an illusration of a person walking"
              loading="eager"
              sx={{
                gridColumnStart: [1, null, null, 2],
                aspectRatio: ['1 / 1', null, '5 / 3', '800 / 720'],
                img: {
                  objectPosition: 'left bottom',
                },
              }}
            />
          )}
          <Container sx={{ display: 'flex', alignItems: 'center' }}>
            <Flex
              sx={{
                maxWidth: [510, null, null, null, 750],
                mx: 'auto',
                py: [4, null, null, 8],
                zIndex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                height: 'full',
              }}
            >
              <ContentBlock
                content={data.content}
                titleSize="Lg"
                captionSize="Md"
                eyebrowStyles={{ fontSize: 'sm' }}
                headingAs="h1"
              />
              <Button
                sx={{
                  variant: 'buttons.primary',
                  appearance: 'none',
                  mt: 4,
                  mb: 2,
                  width: 200,
                }}
                onClick={onQuizStart}
              >
                Start the Quiz
              </Button>
              <Spacer />
              <Flex sx={{ mt: 'auto' }}>
                <Paragraph
                  variant="disclaimer"
                  sx={{
                    textAlign: 'center',
                    fontStyle: 'italic',
                    maxWidth: 400,
                  }}
                >
                  {disclaimer}
                </Paragraph>
              </Flex>
            </Flex>
          </Container>
        </Grid>
      </Section>
    );
  },
);

BunionplastyQuizHero.displayName = 'BunionplastyQuizHero';
