import { graphql } from 'gatsby';

import { SEO } from 'voom-gatsby';

import { BackgroundCallout } from '~sections/BackgroundCallout';
import { BeforeAfterCallout } from '~sections/BeforeAfterCallout';
import { BeforeAfterGrid } from '~sections/BeforeAfterGrid';
import { BeforeAfterSideBySide } from '~sections/BeforeAfterSideBySide';
import { BunionplastyQuiz } from '~sections/BunionplastyQuiz';
import { BunionplastyQuizThankYou } from '~sections/BunionplastyQuizThankYou';
import { BunionplastyTable } from '~sections/BunionplastyTable';
import { FaqSection } from '~sections/FaqSection';
import { FeaturedCarousel } from '~sections/FeaturedCarousel';
import { Form } from '~sections/Form';
import { Hero } from '~sections/Hero';
import { HomeHero } from '~sections/HomeHero';
import { IconCallout } from '~sections/IconCallout';
import { ImageCarousel } from '~sections/ImageCarousel';
import { LegalHero } from '~sections/LegalHero';
import { LegalSection } from '~sections/LegalSection';
import { PreFooter } from '~sections/PreFooter';
import { RevealVideoCallout } from '~sections/RevealVideoCallout';
import { RevealVideoGrid } from '~sections/RevealVideoGrid';
import { SideBySide } from '~sections/SideBySide';
import { SurgeonSearchHero } from '~sections/SurgeonSearchHero';
import { TestimonialCarousel } from '~sections/TestimonialCarousel';
import { Timeline } from '~sections/Timeline';
import { VideoGrid } from '~sections/VideoGrid';
import { VideoSideBySide } from '~sections/VideoSideBySide';

interface SanityTemplateProps {
  data: {
    sanityPage: {
      // To difficult to type out all the section types here
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sections: any[];
    };
    sanityRoute: {
      useSiteTitle?: boolean;
      slug: { current: string };
      openGraph?: {
        title?: string;
        description?: string;
        image?: { asset?: { source?: { url?: string } } };
      };
    };
    sanitySiteSettings: {
      openGraph?: {
        title?: string;
        description?: string;
        image?: { asset?: { source?: { url?: string } } };
      };
    };
  };
}

const SanityTemplate = ({ data }: SanityTemplateProps) => {
  return (
    <>
      {data.sanityPage.sections.map((section, index) => {
        const sectionKey = `${section._type}-${index}`;
        if (section._type === 'homeHero') {
          return <HomeHero key={sectionKey} data={section} index={index} />;
        } else if (section._type === 'hero') {
          return <Hero key={sectionKey} data={section} index={index} />;
        } else if (section._type === 'beforeAfterCallout') {
          return (
            <BeforeAfterCallout key={sectionKey} data={section} index={index} />
          );
        } else if (section._type === 'beforeAfterSideBySide') {
          return (
            <BeforeAfterSideBySide
              key={sectionKey}
              data={section}
              index={index}
            />
          );
        } else if (section._type === 'beforeAfterGrid') {
          return (
            <BeforeAfterGrid key={sectionKey} data={section} index={index} />
          );
        } else if (section._type === 'backgroundCallout') {
          return (
            <BackgroundCallout key={sectionKey} data={section} index={index} />
          );
        } else if (section._type === 'bunionplastyTable') {
          return (
            <BunionplastyTable key={sectionKey} data={section} index={index} />
          );
        } else if (section._type === 'bunionplastyQuiz') {
          return <BunionplastyQuiz key={sectionKey} data={section} />;
        } else if (section._type === 'bunionplastyQuizThankYou') {
          return <BunionplastyQuizThankYou key={sectionKey} data={section} />;
        } else if (section._type === 'faqSection') {
          return <FaqSection key={sectionKey} data={section} index={index} />;
        } else if (section._type === 'featuredCarousel') {
          return (
            <FeaturedCarousel
              key={sectionKey}
              featuredImages={section.featuredImages}
              index={index}
            />
          );
        } else if (section._type === 'form') {
          return <Form key={sectionKey} data={section} index={index} />;
        } else if (section._type === 'iconCallout') {
          return <IconCallout key={sectionKey} data={section} index={index} />;
        } else if (section._type === 'imageCarousel') {
          return (
            <ImageCarousel key={sectionKey} data={section} index={index} />
          );
        } else if (section._type === 'legalHero') {
          return <LegalHero key={sectionKey} data={section} index={index} />;
        } else if (section._type === 'legalSection') {
          return <LegalSection key={sectionKey} data={section} index={index} />;
        } else if (section._type === 'prefooter') {
          return <PreFooter key={sectionKey} data={section} index={index} />;
        } else if (section._type === 'revealVideoCallout') {
          return (
            <RevealVideoCallout key={sectionKey} data={section} index={index} />
          );
        } else if (section._type === 'revealVideoGrid') {
          return (
            <RevealVideoGrid key={sectionKey} data={section} index={index} />
          );
        } else if (section._type === 'sideBySide') {
          return <SideBySide key={sectionKey} data={section} index={index} />;
        } else if (section._type === 'surgeonSearchHero') {
          return (
            <SurgeonSearchHero key={sectionKey} data={section} index={index} />
          );
        } else if (section._type === 'timeline') {
          return <Timeline key={sectionKey} data={section} index={index} />;
        } else if (section._type === 'testimonialCarousel') {
          return (
            <TestimonialCarousel
              key={sectionKey}
              data={section}
              index={index}
            />
          );
        } else if (section._type === 'videoGrid') {
          return <VideoGrid key={sectionKey} data={section} index={index} />;
        } else if (section._type === 'videoSideBySide') {
          return (
            <VideoSideBySide key={sectionKey} data={section} index={index} />
          );
        }
      })}
    </>
  );
};

export const Head = ({ data }: SanityTemplateProps) => {
  return (
    <SEO
      title={
        data.sanityRoute.useSiteTitle || !data.sanityRoute.openGraph?.title
          ? data.sanitySiteSettings.openGraph?.title
          : data.sanityRoute.openGraph?.title
      }
      description={
        data.sanityRoute.openGraph?.description ??
        data.sanitySiteSettings.openGraph?.description
      }
      ogImage={
        data.sanityRoute.openGraph?.image?.asset?.source?.url ??
        data.sanitySiteSettings.openGraph?.image?.asset?.source?.url
      }
      useSiteTitle={
        data.sanityRoute.useSiteTitle || !data.sanityRoute.openGraph?.title
      }
    />
  );
};

export const SANITY_TEMPLATE_QUERY = graphql`
  query SanityTemplate(
    $sanityPageId: String
    $sanityRouteId: String
    $sanitySiteSettingsId: String
  ) {
    sanityPage(id: { eq: $sanityPageId }) {
      sections {
        # ALL FIELD FRAGMENTS ARE LOCATED IN CORRESPONDING COMPONENTS
        ...homeHeroFields
        ...heroFields
        ...backgroundCalloutFields
        ...beforeAfterCalloutFields
        ...beforeAfterGridFields
        ...beforeAfterSideBySideFields
        ...bunionplastyTableFields
        ...bunionplastyQuizFields
        ...bunionplastyQuizThankYouFields
        ...faqSectionFields
        ...featuredCarouselFields
        ...formFields
        ...iconCalloutFields
        ...imageCarouselFields
        ...legalHeroFields
        ...legalSectionFields
        ...preFooterFields
        ...revealVideoCalloutFields
        ...revealVideoGridFields
        ...sideBySideFields
        ...surgeonSearchHeroFields
        ...testimonialCarouselFields
        ...timelineFields
        ...videoSideBySideFields
        ...videoGridFields
      }
      id
    }
    sanityRoute(id: { eq: $sanityRouteId }) {
      slug {
        current
      }
      useSiteTitle
      openGraph {
        title
        description
        image {
          asset {
            source {
              url
            }
          }
        }
      }
    }
    sanitySiteSettings(id: { eq: $sanitySiteSettingsId }) {
      openGraph {
        title
        description
        image {
          asset {
            source {
              url
            }
          }
        }
      }
    }
  }
`;

export default SanityTemplate;
