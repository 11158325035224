import { InfoWindowF, MarkerF, MarkerProps } from '@react-google-maps/api';
import { AnimatePresence, motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useEffect, useState } from 'react';

import { Box, CloseSvg, IconButton, useIsTouchDevice } from 'voom-gatsby';

import { DoctorResultCardCopy } from '~components';

import { DoctorResult } from '~types/sanity';

export const DoctorResultMarker = ({
  position,
  clusterer,
  activeDoctor,
  activeLocation,
  onClick,
  doctor,
}: {
  doctor: DoctorResult;
  position: {
    lat: number;
    lng: number;
  };
  clusterer: MarkerProps['clusterer'];
  activeDoctor: boolean;
  activeLocation: boolean;
  onClick: () => void;
}) => {
  const isTouchDevice = useIsTouchDevice();
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  useEffect(() => {
    if (!activeLocation) {
      setShowInfoWindow(false);
    }
  }, [activeLocation, setShowInfoWindow]);
  return (
    <MarkerF
      title={`${doctor.practiceName} map marker`}
      position={position}
      clusterer={clusterer}
      options={{
        icon: activeDoctor
          ? doctor.listCard
            ? '/icon-geomarker-selected.svg'
            : '/icon-geomarker-selected-secondary.svg'
          : doctor.listCard
            ? '/icon-geomarker-doctor.svg'
            : '/icon-geomarker-doctor-secondary.svg',
        zIndex: activeDoctor ? 1 : 0,
      }}
      onClick={() => {
        onClick();
        setShowInfoWindow(true);
      }}
    >
      <AnimatePresence>
        {showInfoWindow && !isTouchDevice && (
          // infoWindow style overwrites in results.tsx
          <InfoWindowF position={position}>
            <motion.div
              variants={{
                default: { opacity: 0 },
                active: { opacity: 1 },
              }}
              initial="default"
              animate="active"
              exit="default"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 200,
                position: 'relative',
                bg: 'white',
              }}
            >
              <IconButton
                sx={{
                  position: 'absolute',
                  right: 2,
                  top: 2,
                  zIndex: 1,
                  width: 25,
                  height: 25,
                  bg: 'white',
                  svg: {
                    width: '50%',
                    height: '50%',
                  },
                }}
                onClick={() => setShowInfoWindow(false)}
              >
                <CloseSvg />
              </IconButton>
              <Box sx={{ p: 1 }}>
                <GatsbyImage
                  image={doctor.image.asset.gatsbyImageData}
                  alt={doctor.image.alt}
                  sx={{
                    aspectRatio: '175 / 120',
                    width: 'full',
                    img: {
                      borderRadius: 'corner',
                      objectPosition: 'top',
                    },
                  }}
                />
              </Box>
              <DoctorResultCardCopy doctor={doctor} type="MARKER" />
            </motion.div>
          </InfoWindowF>
        )}
      </AnimatePresence>
    </MarkerF>
  );
};
