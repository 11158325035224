import { atom, useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { getDistance, getMapBounds, useGoogleMapInstance } from '~utils/map';

import { DoctorResult, SanityDoctor, SanityDoctorOffice } from '~types/sanity';

import { useUserLocation } from './use-user-location';

export const resultFiltersAtom = atom<{
  distance: number | null;
  consultation: string | null;
  gender: string | null;
  language: string | null;
}>({
  distance: null,
  consultation: null,
  gender: null,
  language: null,
});

export const mobileResultsViewAtom = atom<'list' | 'map'>('list');

export interface SelectedDoctorProps {
  doctor: DoctorResult['id'];
  office: DoctorResult['_key'];
}

const getIsAlreadyInArray = (
  array: DoctorResult[],
  o: Omit<DoctorResult, 'distance'>,
) => {
  return array.some((office) => office.name === o.name);
};

export const useDoctorResults = (
  doctors: SanityDoctor[],
  pageContext: { boundary?: string; abbreviation?: string },
) => {
  const userLocation = useUserLocation();

  const { boundary, abbreviation } = pageContext;

  const { distance } = useAtomValue(resultFiltersAtom);

  const distanceRadius = distance ? distance : 100000;

  const [doctorResults, setDoctorResults] = useState<DoctorResult[] | null>(
    null,
  );
  const [nullStateDoctors, setNullStateDoctors] = useState<
    DoctorResult[] | null
  >(null);

  const [addressDisplay, setAddressDisplay] = useState<string>('');

  const [selectedDoctor, setSelectedDoctor] =
    useState<SelectedDoctorProps | null>(null);

  const allOffices = useMemo(() => {
    return doctors.reduce(
      (array, doctor) => {
        doctor.doctorOffices.map((o) => {
          array.push({
            ...o,
            name: doctor.name,
            id: doctor.id,
            credentials: doctor.credentials,
            consultations: doctor.consultations,
            badges: doctor.badges,
            image: doctor.gallery[0],
            slug: doctor.slug,
            link: `/find-a-doctor/doctors/${doctor.slug.current}?office=${o._key}`,
            contactLink: `/find-a-doctor/doctors/${doctor.slug.current}?office=${o._key}&contact=true`,
            listCard: false,
            additionalOffices: doctor.doctorOffices.filter(
              (office) => office._key !== o._key,
            ),
          });
        });
        return array;
      },
      [] as Omit<DoctorResult, 'distance'>[],
    );
  }, [doctors]);

  const { isLoaded, googleMapInstance, onLoad, onUnmount } =
    useGoogleMapInstance();

  const count =
    doctorResults?.filter((doctor) => doctor.listCard === true).length ?? 0;
  useEffect(() => {
    const getAddressToDisplay = async () => {
      const text = `${count} Bunion Surgery Doctor${count > 1 || count === 0 ? 's' : ''}`;

      if (boundary) setAddressDisplay(`${text} in ${boundary}`);
      if (userLocation !== null && !boundary) {
        setAddressDisplay(`${text} in The United States`);
      }
      if (userLocation?.address) {
        setAddressDisplay(`${text} Near ${userLocation.address}`);
      }

      if (userLocation !== null && !userLocation.address && googleMapInstance) {
        const geocoder = new google.maps.Geocoder();
        const latLng = new google.maps.LatLng(
          userLocation?.lat ?? 0,
          userLocation?.lng ?? 0,
        );

        const { results } = await geocoder.geocode({
          location: latLng,
        });
        const city = results[0].address_components.find((c) =>
          c.types.includes('locality'),
        )?.long_name;
        const state = results[0].address_components.find((c) =>
          c.types.includes('administrative_area_level_1'),
        )?.short_name;
        setAddressDisplay(`${text} Near ${city}, ${state}`);
      }
    };
    getAddressToDisplay();
  }, [googleMapInstance, userLocation, count, boundary]);

  useEffect(() => {
    if (googleMapInstance && boundary) {
      // get boundary location and find offices inside that boundary
      const officesInBoundary = allOffices.reduce((array, o) => {
        const isAlreadyInArray = getIsAlreadyInArray(array, o);
        if (
          o.state.name === boundary ||
          `${o.city.name}, ${o.state.abbreviation}` === boundary
        ) {
          array.push({
            ...o,
            distance: null,
            listCard: isAlreadyInArray ? false : true,
          });
        }
        return array;
      }, [] as DoctorResult[]);

      setDoctorResults(officesInBoundary);

      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: boundary }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK && results) {
          // get viewport of boundary. need abbreviation to get viewport of state due to Washington DC
          const bounds =
            results.find((r) => {
              return r.address_components.find(
                (c) => c.short_name === abbreviation,
              );
            })?.geometry.viewport ?? results[0].geometry.viewport;

          // extend bounds to make sure all offices are in viewport
          officesInBoundary.map(({ geolocation }) => {
            bounds.extend(
              new window.google.maps.LatLng(geolocation.lat, geolocation.lng),
            );
          });
          googleMapInstance.setCenter(results[0].geometry.location);

          googleMapInstance.fitBounds(bounds);
        }
      });
    } else if (googleMapInstance && userLocation) {
      const officesInArea = allOffices
        .reduce((array, o) => {
          const distance = getDistance(userLocation, o.geolocation);
          if (distance < distanceRadius) {
            array.push({ ...o, distance });
          }
          return array;
        }, [] as DoctorResult[])
        .sort((a, b) => (a?.distance ?? 0) - (b?.distance ?? 0)) // sort by distance then remove duplicates
        .reduce((array, o) => {
          const isAlreadyInArray = getIsAlreadyInArray(array, o);
          array.push({ ...o, listCard: isAlreadyInArray ? false : true });
          return array;
        }, [] as DoctorResult[]);

      setDoctorResults(officesInArea);

      const closestThreeOffices = allOffices
        .reduce((array, o) => {
          const distance = getDistance(userLocation, o.geolocation);
          array.push({ ...o, distance });
          return array.sort((a, b) => (a?.distance ?? 0) - (b?.distance ?? 0));
        }, [] as DoctorResult[])
        .splice(0, 3);
      setNullStateDoctors(closestThreeOffices);

      const geoLocations = officesInArea.map((o) => {
        return o.geolocation;
      });
      if (geoLocations.length > 0) {
        geoLocations.push(userLocation); // add userLocation
        googleMapInstance && getMapBounds(googleMapInstance, geoLocations);
      }
    }
  }, [
    doctors,
    googleMapInstance,
    userLocation,
    allOffices,
    distanceRadius,
    boundary,
    abbreviation,
  ]);

  return {
    isLoaded,
    onLoad,
    onUnmount,
    googleMapInstance,
    addressDisplay,
    selectedDoctor,
    setSelectedDoctor,
    userLocation,
    doctorResults,
    nullStateDoctors,
  };
};
