import { PageProps, graphql } from 'gatsby';
import { useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import {
  BreadCrumbs,
  Button,
  Container,
  Grid,
  Heading,
  Paragraph,
  SEO,
  Spacer,
  StyledSup,
  Text,
} from 'voom-gatsby';

import { BeforeAfterCard } from '~components';

import { ReactComponent as CalendarIcon } from '~svg/icons/icon-calendar.svg';

import { BeforeAfterCardProps, SanityDoctorOffice } from '~types/sanity';

import { DoctorModalForm } from '../sections/DoctorModalForm';

interface DoctorPatientGalleryTemplateProps {
  data: {
    sanityDoctor: {
      name: string;
      slug: {
        current: string;
      };
      doctorOffices: SanityDoctorOffice[];
    };
    allSanityBeforeAfterCard: {
      nodes: BeforeAfterCardProps[];
    };
  };
  location: PageProps['location'];
}

const DoctorPatientGalleryTemplate = ({
  data,
  location,
}: DoctorPatientGalleryTemplateProps) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const openFormModal = () => setShowFormModal(true);
  const closeFormModal = () => setShowFormModal(false);
  const doctor = data.sanityDoctor;
  const [query] = useQueryParams({
    office: StringParam,
  });
  const initialOffice = doctor.doctorOffices.find(
    (office) => office._key === query.office,
  );
  const [selectedOffice, setSelectedOffice] = useState(
    initialOffice || doctor.doctorOffices[0],
  );
  return (
    <>
      <Container>
        <Spacer />
        <BreadCrumbs
          links={[
            { url: '/find-a-doctor/doctors', label: 'All Doctors' },
            {
              url: `/find-a-doctor/doctors/${doctor.slug.current}`,
              label: doctor.name,
            },
            {
              url: `/find-a-doctor/doctors/${doctor.slug.current}/before-after`,
              label: 'Patient Gallery',
            },
          ]}
        />
        <Heading sx={{ fontSize: ['2xl', null, '5xl'] }}>
          {`See Dr. ${doctor.name}’s results for yourself.`}
        </Heading>
        <Spacer space={[3, null, 2]} />
        <Paragraph sx={{ maxWidth: 700 }}>
          <StyledSup>
            {`The Bunionplasty® 360 Bunion Repair™ procedure means beautiful results from every angle – and delighted patients who can’t wait to show off their new feet.`}
          </StyledSup>
        </Paragraph>
        <Spacer />
        <Button
          onClick={openFormModal}
          sx={{
            display: 'flex',
            mt: 'auto',
            py: '10px',
            px: 4,
            alignItems: 'center',
          }}
        >
          <Text sx={{ fontWeight: 'semibold', mr: 2 }}>
            Request This Doctor
          </Text>
          <CalendarIcon sx={{ color: 'white', width: 17, height: 17 }} />
        </Button>

        <DoctorModalForm
          close={closeFormModal}
          open={openFormModal}
          isOpen={showFormModal}
          doctorName={doctor.name}
          locations={doctor.doctorOffices}
          selectedOffice={selectedOffice || doctor.doctorOffices[0]}
          setSelectedOffice={setSelectedOffice}
        />
        <Spacer space={5} />
        <Paragraph>{`${data.allSanityBeforeAfterCard.nodes.length} Results`}</Paragraph>
        <Spacer />
        <Grid columns={[1, 2, 3]} gap={7}>
          {data.allSanityBeforeAfterCard.nodes.map((card, index) => {
            return (
              <BeforeAfterCard
                {...card}
                key={
                  card.image.asset.gatsbyImageData.images.fallback?.src ?? index
                }
              />
            );
          })}
        </Grid>
      </Container>
    </>
  );
};

export const Head = ({ data }: DoctorPatientGalleryTemplateProps) => {
  return (
    <SEO
      title={`${data.sanityDoctor.name}`}
      description={`Dr. ${data.sanityDoctor.name} is specially trained to help you get back on your feet fast with Bunionplasty® 360 Repair™ bunion surgery, so schedule your consult today!`}
    />
  );
};

export const DOCTOR_PATIENT_GALLERY_TEMPLATE_QUERY = graphql`
  query DoctorPatientGalleryTemplateQuery($sanityDoctorId: String) {
    sanityDoctor(id: { eq: $sanityDoctorId }) {
      name
      slug {
        current
      }
      doctorOffices {
        city {
          name
        }
        state {
          abbreviation
        }
      }
    }
    allSanityBeforeAfterCard(
      filter: { doctor: { id: { eq: $sanityDoctorId } } }
    ) {
      nodes {
        image {
          alt
          asset {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          }
        }
        severity {
          value
        }
      }
    }
  }
`;

export default DoctorPatientGalleryTemplate;
