import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { FC, useEffect, useRef, useState } from 'react';
import ReactSlickSlider, { Settings } from 'react-slick';

import {
  Box,
  Container,
  Grid,
  Heading,
  IconButton,
  Section,
  Spacer,
} from 'voom-gatsby';

import { DoctorOfficeCard } from '~components';

import { getMapBounds, mapOptions, useGoogleMapInstance } from '~utils/map';

import { ReactComponent as ArrowIcon } from '~svg/icons/icon-arrow-circle.svg';

import { SanityDoctor } from '~types/sanity';

interface SliderArrowProps {
  onClick?: () => void;
  prev?: boolean;
}

export const SliderArrow = ({ onClick, prev }: SliderArrowProps) => {
  return (
    <IconButton
      onClick={onClick}
      variant="iconGhost"
      aria-label={`Go to ${prev ? 'previous' : 'next'} location`}
      sx={{
        position: 'absolute',
        zIndex: 2,
        bottom: [4, null, 6],
        p: 0,
        left: prev
          ? [4, null, 6]
          : [`calc(24px + 40px)`, null, `calc(48px + 40px)`],
        width: 30,
        height: 30,
        svg: { width: 30, height: 30 },
      }}
    >
      <ArrowIcon
        sx={{
          color: 'primary',
          ...(prev ? { transform: 'rotate(180deg)' } : {}),
        }}
      />
    </IconButton>
  );
};

const getLocationsHeader = (doctorOffices: SanityDoctor['doctorOffices']) => {
  const states = doctorOffices.reduce((acc, office) => {
    if (!acc.includes(office.state.name)) {
      acc.push(office.state.name);
    }
    return acc;
  }, [] as string[]);
  return states.length > 1
    ? `${states.slice(0, -1).join(', ')} & ${states[states.length - 1]}`
    : states[0];
};

interface DoctorOfficesProps {
  doctor: SanityDoctor;
  selectedOffice: SanityDoctor['doctorOffices'][0];
  setSelectedOffice: (office: SanityDoctor['doctorOffices'][0]) => void;
}

export const DoctorOffices: FC<DoctorOfficesProps> = ({
  doctor,
  selectedOffice,
  setSelectedOffice,
}) => {
  const { doctorOffices } = doctor;
  const officeGeoLocations = doctorOffices.map((o) => o.geolocation);
  const needsMapBounds = officeGeoLocations.length > 1; // only need to set map bounds if there are multiple locations
  const ref = useRef<ReactSlickSlider>(null);

  const locationsHeader = getLocationsHeader(doctorOffices);

  const { isLoaded, googleMapInstance, onLoad, onUnmount } =
    useGoogleMapInstance();

  const selectedOfficeIndex = doctorOffices.findIndex(
    (office) => office.streetAddress === selectedOffice.streetAddress,
  );

  const [slideIndex, setSlideIndex] = useState(selectedOfficeIndex);

  const doctorOfficesCarouselSettings: Settings = {
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    prevArrow: <SliderArrow prev={true} />,
    nextArrow: <SliderArrow />,
    beforeChange: (_, next) => {
      setSlideIndex(next);
      // reset map bounds
      googleMapInstance &&
        needsMapBounds &&
        getMapBounds(googleMapInstance, officeGeoLocations);
    },
  };

  useEffect(() => {
    if (googleMapInstance && needsMapBounds) {
      getMapBounds(googleMapInstance, officeGeoLocations);
    }
  }, [googleMapInstance, needsMapBounds, officeGeoLocations]);

  useEffect(() => {
    if (selectedOfficeIndex !== -1) {
      setSlideIndex(selectedOfficeIndex);
      ref.current?.slickGoTo(selectedOfficeIndex);
    }
  }, [selectedOfficeIndex]);

  const handleMarkerClick = (index: number) => {
    ref.current?.slickGoTo(index);
    googleMapInstance &&
      needsMapBounds &&
      getMapBounds(googleMapInstance, officeGeoLocations);
  };

  return (
    <Section
      sx={{
        py: [5, null, 7],
        bg: 'white',
        overflow: 'hidden',
      }}
    >
      <Container
        variant="doctorContainer"
        sx={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Heading variant="doctorSectionHeading">{`Locations in ${locationsHeader}`}</Heading>
        <Spacer />
        <Grid
          sx={{
            gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
            bg: 'grey200',
            minHeight: 440,
            borderRadius: 'radius',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              minHeight: 325,
              width: '100%',
            }}
          >
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                center={officeGeoLocations[0]}
                zoom={11}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={mapOptions}
              >
                {doctorOffices.map((o, index) => {
                  return (
                    <MarkerF
                      position={{
                        lat: o.geolocation.lat,
                        lng: o.geolocation.lng,
                      }}
                      title={`${o.practiceName} map marker`}
                      key={o.geolocation.lat}
                      onClick={() => handleMarkerClick(index)}
                      options={{
                        icon:
                          index === slideIndex
                            ? '/icon-geomarker-selected.svg'
                            : '/icon-geomarker-doctor.svg',
                      }}
                    />
                  );
                })}
              </GoogleMap>
            )}
          </Box>
          <ReactSlickSlider
            {...doctorOfficesCarouselSettings}
            // We need ref to manage slider but the package's ts definition does not include ref
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={ref}
            beforeChange={(_, next) => {
              setSelectedOffice(doctorOffices[next]);
            }}
            sx={{
              overflow: 'hidden',
              '.slick-slide[aria-hidden="true"]': { pointerEvents: 'none' },
            }}
          >
            {doctorOffices.map((office, index) => (
              <DoctorOfficeCard
                key={office.streetAddress + index}
                office={office}
                index={index}
                officeCount={doctorOffices.length}
              />
            ))}
          </ReactSlickSlider>
        </Grid>
      </Container>
    </Section>
  );
};
