import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Container, Flex, Grid, Link, Section } from 'voom-gatsby';

import { BunionplastyBackground, ContentBlock } from '~components';

import { ContentBlockProps as ContentBlockInterface } from '~types/sanity';

type BunionplastyQuizThankYouProps = {
  data: {
    content: ContentBlockInterface;
  };
};

export const BunionplastyQuizThankYou = (
  props: BunionplastyQuizThankYouProps,
) => {
  const { illustration } = useStaticQuery(HERO_ILLUSTRATION_QUERY);
  const heroImage = getImage(illustration);
  const { data } = props;

  return (
    <Section
      sx={{
        bg: 'beige',
        pt: [0, 0, 0, 0],
        pb: [0, 0, 0, 0],
      }}
    >
      <BunionplastyBackground
        sx={{
          left: [0, null, -400],
          bottom: [0, null, 500],
          width: [0, null, 1400],
          zIndex: [0, null, -1],
        }}
      />
      <Grid
        columns={[1, null, null, 2]}
        sx={{
          gridAutoFlow: 'dense',
        }}
      >
        {heroImage && (
          <GatsbyImage
            image={heroImage}
            alt="an illusration of a person walking"
            loading="eager"
            sx={{
              gridColumnStart: [1, null, null, 2],
              aspectRatio: ['1 / 1', null, '5 / 3', '800 / 720'],
              img: {
                objectPosition: 'left bottom',
              },
            }}
          />
        )}
        <Container sx={{ display: 'flex', alignItems: 'center' }}>
          <Flex
            sx={{
              maxWidth: [510, null, null, null, 750],
              mx: 'auto',
              py: [4, null, null, 8],
              zIndex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: 'full',
            }}
          >
            <ContentBlock
              content={data.content}
              titleSize="Lg"
              captionSize="Md"
              eyebrowStyles={{ fontSize: 'sm' }}
              headingAs="h1"
            />
            <Link
              variant="button"
              sx={{ whiteSpace: 'nowrap', mt: 4, mb: 2 }}
              href={'/doctors'}
            >
              Explore Our Doctors
            </Link>
            <Link
              variant="buttonOutline"
              sx={{ whiteSpace: 'nowrap', mt: 4, mb: 2 }}
              href="/frequently-asked-questions"
            >
              Frequently Asked Questions
            </Link>
          </Flex>
        </Container>
      </Grid>
    </Section>
  );
};

export const HERO_ILLUSTRATION_QUERY = graphql`
  query BunionplastyQuizHeroIllustration {
    illustration: file(relativePath: { eq: "bp-quiz-hero-illustration.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
`;

export const BUNIONPLASTY_QUIZ_THANK_YOU_QUERY = graphql`
  fragment bunionplastyQuizThankYouFields on SanityBunionplastyQuizThankYou {
    _type
    content {
      ...sanityContentBlockFields
    }
  }
`;
