import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useForm } from 'react-hook-form';
import {
  Honeypot,
  NetlifyFormComponent,
  NetlifyFormProvider,
  useNetlifyForm,
} from 'react-netlify-forms';

import {
  Box,
  Button,
  Container,
  Flex,
  FormInput,
  FormSelect,
  Grid,
  Modal,
  ModalProps,
  Paragraph,
  StyledSup,
  useDefaultFormFields,
} from 'voom-gatsby';

import { ContentBlock } from '~components';

import { SanityDoctorOffice } from '~types/sanity';

interface FormProps {
  doctorName: string;
  locations: SanityDoctorOffice[];
  selectedOffice: SanityDoctorOffice;
  setSelectedOffice: (office: SanityDoctorOffice) => void;
}

const Form = ({
  doctorName,
  locations,
  selectedOffice,
  setSelectedOffice,
}: FormProps) => {
  const { sanityDoctorSettings } = useStaticQuery(SANITY_DOCTOR_MODAL_QUERY);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const netlify = useNetlifyForm({
    name: 'Doctor Request form',
    action: '/thanks',
    honeypotName: 'bot-field',
    onSuccess: () => {
      if (window.gtag) {
        window.gtag('event', 'Form Submission - Doctor Request', {});
      }
      reset();
      navigate('?form-submitted=true');
    },
  });

  const DefaultFormFields = useDefaultFormFields(register, reset);

  const onSubmit = (data: unknown) => netlify.handleSubmit(null, data);

  const locationOptions = locations.map(
    (location) => `${location.city.name}, ${location.state.abbreviation}`,
  );

  const selectedLocation = selectedOffice
    ? `${selectedOffice.city.name}, ${selectedOffice.state.abbreviation}`
    : null;

  return (
    <Box
      sx={{
        bg: 'beige',
        overflowY: 'scroll',
      }}
    >
      <Container
        sx={{
          maxWidth: [500, null, null, null, 700],
          pt: [8, null, 9],
          pb: [7, null, 9],
        }}
      >
        {netlify.success ? (
          <ContentBlock
            content={sanityDoctorSettings.doctorModalFormSuccessContent}
            sx={{
              textAlign: 'center',
            }}
          />
        ) : (
          <>
            <ContentBlock
              content={sanityDoctorSettings.doctorModalFormContent}
              sx={{
                textAlign: 'center',
              }}
            />
            <NetlifyFormProvider {...netlify}>
              <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
                <Honeypot />
                <Grid columns={2} sx={{ rowGap: 0 }}>
                  <FormInput
                    label="First Name"
                    name="firstName"
                    register={register('firstName', { required: true })}
                    errors={errors.firstName}
                    control={control}
                  />
                  <FormInput
                    label="Last Name"
                    name="lastName"
                    register={register('lastName', { required: true })}
                    errors={errors.lastName}
                    control={control}
                  />
                  <FormInput
                    label="Email Address"
                    name="email"
                    register={register('email', { required: true })}
                    errors={errors.email}
                    control={control}
                  />
                  <FormInput
                    label="Phone Number"
                    name="phoneNumber"
                    type="tel"
                    register={register('phoneNumber', { required: true })}
                    errors={errors.phoneNumber}
                    control={control}
                  />
                  <FormSelect
                    label="Location of Interest"
                    register={register('location', { required: true })}
                    options={locationOptions}
                    sx={{ gridColumn: '1 / -1' }}
                    setValue={setValue}
                    selectedItem={selectedOffice ? selectedLocation : null}
                    onSelectedItemChange={(selectedItem) => {
                      const [city, state] = selectedItem.split(', ');
                      const selectedOffice = locations.find(
                        (location) =>
                          location.city.name === city &&
                          location.state.abbreviation === state,
                      );
                      if (selectedOffice) setSelectedOffice(selectedOffice);
                    }}
                  />
                  <FormInput
                    type="textarea"
                    label="Message"
                    placeholder="Tell us about the impact bunions are having on your body and your life - and what you’d like to do after they’re repaired!"
                    name="message"
                    Textarea={true}
                    register={register('message', { required: true })}
                    errors={errors.message}
                    control={control}
                    sx={{ gridColumn: '1 / -1' }}
                  />
                  <input
                    type="hidden"
                    {...register('doctor')}
                    value={doctorName}
                  />
                  <DefaultFormFields />
                </Grid>
                {netlify.error && (
                  <Paragraph sx={{ py: 4, textAlign: 'center' }}>
                    Sorry, we could not reach servers. Please try again later.
                    If this problem continues, please contact us. Thanks!
                  </Paragraph>
                )}
                <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                  <Button
                    type="submit"
                    sx={{
                      variant: 'buttons.primary',
                      appearance: 'none',
                      mt: 4,
                      mb: 2,
                      width: 200,
                    }}
                  >
                    Submit
                  </Button>
                  <Paragraph variant="copyright">All fields required</Paragraph>
                  {sanityDoctorSettings.doctorModalFormDisclaimer && (
                    <Paragraph
                      variant="disclaimer"
                      sx={{
                        textAlign: 'center',
                        mt: 6,
                      }}
                    >
                      <StyledSup>
                        {sanityDoctorSettings.doctorModalFormDisclaimer}
                      </StyledSup>
                    </Paragraph>
                  )}
                </Flex>
              </NetlifyFormComponent>
            </NetlifyFormProvider>
          </>
        )}
      </Container>
    </Box>
  );
};

export const DoctorModalForm = ({
  isOpen,
  open,
  close,
  doctorName,
  locations,
  selectedOffice,
  setSelectedOffice,
}: Omit<ModalProps, 'children'> & FormProps) => {
  return (
    <>
      <Modal
        close={close}
        open={open}
        isOpen={isOpen}
        css={{
          // use fallback for mobile height
          '&[data-reach-dialog-content]': {
            height: ['100vh', '100dvh'],
            maxHeight: ['100vh', '100dvh'],
          },
        }}
        sx={{
          '&[data-reach-dialog-content]': {
            maxWidth: 800,
            maxHeight: [null, '80vh !important'],
            width: ['100vw', '95vw', '80vw'],
            borderRadius: [0, 'rounded'],
          },
        }}
      >
        <Form
          doctorName={doctorName}
          locations={locations}
          selectedOffice={selectedOffice}
          setSelectedOffice={setSelectedOffice}
        />
      </Modal>
      {/* Add this to the HTML so netlify can capture the modal form */}
      <Box aria-hidden="true" sx={{ display: 'none', visibility: 'hidden' }}>
        <Form
          doctorName={doctorName}
          locations={locations}
          selectedOffice={selectedOffice}
          setSelectedOffice={setSelectedOffice}
        />
      </Box>
    </>
  );
};

export const SANITY_DOCTOR_MODAL_QUERY = graphql`
  query SanityDoctorModal {
    sanityDoctorSettings(id: { eq: "-8b30ce4e-87a0-5afb-bf8c-c8982d88641d" }) {
      doctorModalFormContent {
        ...sanityContentBlockFields
      }
      doctorModalFormDisclaimer
      doctorModalFormSuccessContent {
        ...sanityContentBlockFields
      }
    }
  }
`;
